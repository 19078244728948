* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: linear-gradient(#399294c1, #287b57c1);
  width: 100%;
  height: 100vh;
  font-family: "Comfortaa", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  width: 95%;
  padding: 0 20px;
  margin: auto;
}

.card {
  width: 100%;
  margin: 30px auto;
  background: linear-gradient(#399294, #287b57);
  padding: 10px;
  border-radius: 30px;
}
.search {
  width: 100%;
  position: relative;

  input {
    width: 100%;
    padding: 20px 15px;
    border-radius: 25px;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    background-color: #2926267c;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #fff;
    }
  }
  button {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    right: 10%;
    background: transparent;
    border: none;
    font-size: 1.6rem;
    color: #fff;
  }
}

.error {
  padding: 10px;
  font-size: 1rem;
  color: #fff;
}
.weather {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 80%;
  margin: 30px auto 15px;
  text-align: center;
  .weather-icon {
    width: 35%;
    margin: 10px auto;
  }
  h1 {
    font-size: 3rem;
    color: #fff;
  }
  h2 {
    font-size: 2.4rem;
    color: #fff;
  }
  h4 {
    font-size: 1.2rem;
    font-weight: 500;
    color: #ddd;
  }
}

.weather-week {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  margin: 20px auto;
  .weather-day {
    width: 100%;
    padding: 15px 10px 5px;
    display: grid;
    grid-template-rows: 3fr 1fr 2fr;
    .weather-icon {
      width: 95%;
      margin: 10px auto;
    }
    h5 {
      font-size: 1rem;

      color: #eee;
      font-weight: 500;
    }
    .day {
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

/* TABLET */
@media only screen and (min-width: 620px) {
  .main {
    width: 65%;
    padding: 0 20px;
    margin: auto;
    position: relative;
  }
  .card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    margin: 0 auto;
  }
}

/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
  body {
    height: 100vh;
    overflow: hidden;
  }
  .card {
    min-width: 400px;
    background: linear-gradient(#399294, #287b57);
    border-radius: 30px;
    .search {
      width: 100%;
      input {
        font-size: 1.4rem;
      }
    }
  }
}
